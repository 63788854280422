body {
  overflow-x: hidden;
}

#router-container {
  position: relative;
}

.disabled-unresponsive {
  pointer-events: none;
}